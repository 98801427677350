<template>
  <v-container fluid class="px-6">
    <v-card>

      <v-card-title class="px-6 card-header white--text">
          <v-icon class="mr-3 white--text">mdi-account-eye</v-icon> <h3>User Log List</h3>
      </v-card-title>

      <v-row class="px-8 pt-4">
        <v-col cols="3">
          <v-menu
            v-model="fmenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fdate"
                label="From Date"
                prepend-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fdate"
              @input="fmenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="3">
          <v-menu
            v-model="tmenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="tdate"
                label="To Date"
                prepend-icon="mdi-calendar-end"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="tdate"
              @input="tmenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="1"> </v-col>

        <v-col cols="4" align="right">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            dense
          ></v-text-field>
        </v-col>

        <v-col cols="1">
          <v-btn
            color="excel"
            elevation="2"
            @click="exportReport('excel')"
            :disabled="newList == null"
          >
            <v-icon color="white" center dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headersList"
        :items="newList"
        :items-per-page="5"
        class="elevation-1 px-6 admin_table"
        :search="search"
      >
        <template v-slot:item.dataValidationStatus>
          <v-chip color="green" dark>
            Finish Processing
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <template>
            <v-icon small class="mr-2 blue--text" @click="formRequest(item)"
              >mdi-pencil</v-icon
            >
          </template>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import appMixins from "@/mixins/globalMixins";

import Papa from "papaparse";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// -- import something here --

export default {
  data: () => ({
    search: "",
    formApplication: false,
    detailForm: {},
    statusForm: ["Lulus", "Tolak"],
    formStatus: "Lulus",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,

    fmenu: false,
    tmenu: false,
    tdate: "",
    fdate: "",

    headersList: [
      {
        text: "USER ID",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "HOST", value: "email" },
      { text: "IP", value: "idUser" },
      { text: "ACTIVITY", value: "userAccessFname" },
      { text: "Updated Date & Time", value: "lastPwdChange" },
    ],

    headersList2: [
      {
        text: "Agency",
        align: "start",
        sortable: false,
        value: "idUser",
      },
      { text: "Station ID", value: "email" },
      { text: "Location", value: "lastPwdChange" },
      { text: "Status", value: "dataValidationStatus" },
      { text: "Expired Date", value: "actions" },
      { text: "Remarks", value: "dataValidationStatus" },
    ],

    newFormDetails: {
      agency: "JAS",
      stationID: "CMS02",
      location: "SUNGAI PERAK",
      status: "Lulus",
      expiredDate: "07-02-2023",
      remarks: "Contaminated",
    },

    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: "Not Process",
        protein: 4.0,
        iron: 1,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
        fat: 9.0,
        carbs: "Not Process",
        protein: 4.3,
        iron: 1,
      },
      {
        name: "Eclair",
        calories: 262,
        fat: 16.0,
        carbs: "Not Process",
        protein: 6.0,
        iron: 7,
      },
      {
        name: "Cupcake",
        calories: 305,
        fat: 3.7,
        carbs: "Not Process",
        protein: 4.3,
        iron: 8,
      },
      {
        name: "Gingerbread",
        calories: 356,
        fat: 16.0,
        carbs: "Not Process",
        protein: 3.9,
        iron: 16,
      },
    ],

    newList: [],
  }),

  mounted() {
    console.log("welcome home");

    this.getAllUser();
  },

  methods: {
    getAllUser() {
      axios
        .get(appMixins.data().globalUrl + `/sarawak/users/all`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.newList = response.data;
          console.log(this.userData);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    formRequest(item) {
      this.formApplication = true;
      console.log(this.formApplication, item);
      this.detailForm = item;
    },

    // Export Table into PDF/Excel
    exportReport() {
      let valueHeader = [];
      let nameHeader = [];

      for (let i = 0; i < this.headersList.length; i++) {
        valueHeader.push(this.headersList[i].value);
        nameHeader.push(this.headersList[i].text);
      }

      console.log(nameHeader);

      let keys = valueHeader,
        result = this.newList.map((o) =>
          Object.assign(
            ...keys.map((k, index) => ({
              [nameHeader[index]]: o[k],
            }))
          )
        );

      // console.log(result);
      // result.forEach(function(res){
      //   res["DATETIME"] = res["DATETIME"].replaceAll('<br>','T');
      // });

      console.log(result);

      var blob = new Blob([Papa.unparse(result)], {
        type: "text/csv;charset=utf-8;",
      });

      var link = document.createElement("a");

      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "User List Log.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.background {
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  top: 0;
  right: 0;
}

.form {
  // width: 500px;
}
</style>
